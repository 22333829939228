@import '../../../icape-da';

/* ----- PANEL ----- */
.project-panel-wrapper{
    min-width: 425px;
    max-height: 100%;
/*    height: calc(100vh - 110px);*/
    padding: 50px 0 0 0;
    background-color: $softBlue3;
    /*border-left: 2px solid $softGrey2;*/
    box-shadow: $border-shadow;
    transition: box-shadow 0.3s ease-in-out;
    /*background-image: linear-gradient(180deg, $softBlue 0%, $blueIcape 100%);*/
    color: $black;
    display: flex;
    flex-direction: column;
    align-items: center;
    /*justify-content: space-around;*/
    gap: 50px;
    position: sticky;
    top: 110px;

    &:hover{
        box-shadow: $offsetX $offsetY $blur rgba(53,53,53,.3);
    }
}
.project-panel-price-matrix-wrapper{
    display: flex;
    align-items: center;
    gap: 20px;
    flex-direction: column;
    width: 100%;
    padding: 0 10px;
}
.project-panel-price-matrix{
    width: 100%;

    table sup {
        font-size: 10px;
    }
    table td, table th{
        font-size: 14px;
    }

    /*
    table tr:last-child td,
    table tr:last-child th,
    */
    table thead th{
        font-weight: 600;
        background-color: $softBlue;
        color: $white;
        font-size: 16px;
    }

    .tableCellQuantityCustomer td{
        //background-color: $softGreen;
        font-weight: 800;
    }
}
.price-matrix-legend{
    font-size: 12px;
    margin-top: -15px;
    align-self: flex-end;
}
.panel-project-info-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    width: 100%;
    padding: 0 10px;
}
.panel-discount{
    font-weight: 500;
    background-color: $softGreen;
    width: 100%;
    padding: 10px 0;
    text-align: center;
    border-radius: $border-radius;

    span{
        font-size:18px;
        font-weight: 600;
    }
}
.panel-pricing{
    width: 100%;
    border-collapse: collapse;

    td{
        padding: 5px 0;
        position: relative;
    }
    td:last-child{
        text-align: right;
    }

    tbody tr:last-child td {
        padding-bottom: 20px;
    }
    tfoot{
        font-size: 18px;
        font-weight: 600;

        td{
            border-top: solid $darkBlue 2px;
        }

       .table-price-lock{
           color: $red;
           display: flex;
           gap: 10px;
           justify-content: flex-end;
           align-content: center;

           svg{
               font-size: 20px;
           }
        }
    }
}
.helper-ban{
    width: 100%;
    height: 120px;

    border-top: solid $softGrey2 2px;
    /*background-color: $softBlue2;*/
    display: flex;
    justify-content: center;
    gap:30px;
    padding: 20px;
    align-items: center;
    a{
        max-width: 380px;
        text-decoration: none;
    }
}
.helper-ban-icon{
    width: 90px;
    height: 90px;
    svg{
        width: 100%;
        height: 100%;
    }
}

/* ------ TABLET ------ */
@media screen and (max-width: 1200px) {
    .project-panel-wrapper{
        width: 100%;
        min-width: auto;
        padding: 0 0 50px 0;
        position: static;
    }
    .project-panel-price-matrix-wrapper{
        padding: 50px 50px 0 50px;
    }
    .project-panel-price-matrix{
        table td, table th{
            font-size: 14px;
        }
    }
    .panel-project-info-wrapper{
        padding: 0 50px;
    }
}

/* ------ MOBILE ------ */
@media screen and (max-width: 700px) {
    .project-panel-price-matrix-wrapper{
        padding: 50px 10px 0 10px;
    }
}