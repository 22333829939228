/* ----- FONTS ----- */
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-Thin.ttf") format("truetype");
  font-weight: 100;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-ThinItalic.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-ExtraLight.ttf") format("truetype");
  font-weight: 200;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-ExtraLightItalic.ttf") format("truetype");
  font-weight: 200;
  font-style: italic;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-Light.ttf") format("truetype");
  font-weight: 300;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-Regular.ttf") format("truetype");
  font-weight: 400;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-Italic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-Medium.ttf") format("truetype");
  font-weight: 500;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-SemiBold.ttf") format("truetype");
  font-weight: 600;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-Bold.ttf") format("truetype");
  font-weight: 700;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-BoldItalic.ttf") format("truetype");
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-ExtraBold.ttf") format("truetype");
  font-weight: 800;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-ExtraBoldItalic.ttf") format("truetype");
  font-weight: 800;
  font-style: italic;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-Black.ttf") format("truetype");
  font-weight: 900;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
}

/* ----- COLORS ----- */
$darkBlue: #00588C;
$darkBlue2: #15294b;
$darkBlue3: #181e21;
$blueIcape: #007DC8;
$softBlue: #37B0F1;
$softBlue2: #80D3FF;
$softBlue3: #E6F2F8;

$darkGreen: #386957;
$green: #94CB6E;
$softGreen: #BAE19F;

$purple: #4F4387;
$softPurple: #6E5DBA;

$red: #CC5A71;
$darkRed: #80475E;

$yellow: #f7f434;

$black:#353535;
$grey:#6E6E6E;
$softGrey:#A9A9A9;
$softGrey2:#D2D2D2;
$softGrey3:#F0F0F0;

$darkWhite:#FAFAFA;
$white: #FFFFFF;

$working-days-color: $blueIcape;
$shipping-days-color: $darkBlue;


/* ----- BORDER ----- */
$offsetX: 0;
$offsetY: 3px;
$blur: 10px;
$color-shadow: rgba(53, 53, 53, 0.1);
$border-shadow: $offsetX $offsetY $blur $color-shadow;

$border-radius: 10px;

:export {
  darkBlue: $darkBlue;
  darkBlue2: $darkBlue2;
  darkBlue3: $darkBlue3;
  blueIcape: $blueIcape;
  softBlue: $softBlue;
  softBlue2: $softBlue2;
  softBlue3: $softBlue3;

  darkGreen: $darkGreen;
  green: $green;
  softGreen: $softGreen;

  purple: $purple;

  red: $red;
  darkRed: $darkRed;

  yellow: $yellow;

  black: $black;
  grey: $grey;
  softGrey: $softGrey;
  softGrey2: $softGrey2;
  softGrey3: $softGrey3;

  darkWhite: $darkWhite;
  white: $white;

  offsetX: $offsetX;
  offsetY: $offsetY;
  blur: $blur;
  colorShadow: $color-shadow;
  borderShadow: $border-shadow;
  borderRadius: $border-radius;
}
