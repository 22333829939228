@import './../../icape-da';

.news-container{
  width: 100%;
  max-width: 1300px;
  //max-width: 1530px;
  margin: 30px auto 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  gap: 50px;

  .MuiDivider-root{
    border-color: $softBlue;
  }
}

.videos-update-container{
  max-width: 400px;
  flex-grow: 1;
  border-radius: $border-radius;
  padding: 20px 20px 20px 0;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 20px;


  //MUI CUSTOMIZATION TIMELINE
  .MuiTimeline-root{
    width: 100%;
  }
  .MuiTimelineItem-root{
    cursor: pointer;
    span{
      color: $darkBlue;
      font-size: 12px;
      font-weight: bold;
    }
    h3{
      font-weight: normal;
    }
    &:before{
      //flex: 0 !important;
      display: none;
    }
    &:hover{
      .MuiTimelineContent-root{
        transition: padding-left 0.2s ease-in-out;
        padding-left: 20px;
      }
    }
    .MuiTimelineContent-root{
      transition: padding-left 0.2s ease-in-out;
    }
  }
  .MuiTimelineDot-filled, .MuiTimelineConnector-root{
    background-color: $blueIcape;
  }
  .MuiTimelineDot-outlined:first-of-type{
    border-color: $green;
  }
  .icon-green{
    color: $green;
  }
}


/* ------ MOBILE ------ */
@media screen and (max-width: 1200px) {
  .news-container{

    flex-direction: column;
    align-content: center;
    align-items: center;

    hr{
      display: none;
    }
  }
}