@import '../../../icape-da';

.card-manager-wrapper{
  display: flex;
  flex-direction: column;
  gap: 50px;
}
.card-manager-content{
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
.card-manager-pagination{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}


.order-resume-card{
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 250px;
  border: 1px solid $blueIcape;
  border-radius: $border-radius;
  padding: 10px;
}
.order-resume-card-date{
  align-self: flex-end;
  font-size: 12px;
  font-weight: 500;
}
.order-resume-card-content{
  display: flex;
  flex-direction: column;
  gap: 5px;

  div{
    display: flex;
    justify-content: space-between;

    >span:last-child{
      color: $blueIcape;
    }
  }
}
.order-resume-card-status{
  color: $red;
  margin-top: 30px;
  font-weight: 500;
}
.order-resume-card-price{
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  span{
    font-size: 12px;
    color: $blueIcape;
  }
}



/* ------ TABLET ------ */
@media screen and (max-width: 1200px){

}

/* ------ MOBILE ------ */
@media screen and (max-width: 700px) {
  .card-manager-content{
    justify-content: center;
  }
}