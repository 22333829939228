@import '../../../icape-da';

.panelization-wrapper {
    background-color: $white;
    display: flex;
    flex-wrap: wrap;
    padding: 30px 20px 20px 20px;
    width: 100%;
    border-radius: $border-radius;
    box-shadow: $border-shadow;

    .MuiFormControl-root{
        max-width: 200px;
    }
}

.panelization-top-bar{
    display: flex;
    gap: 20px;
    justify-content: space-between;
    width: 100%;

    > div{
        display: flex;
        gap: 20px;
    }

    svg{
        fill: $softBlue;
        cursor: pointer;

        &:hover{
            fill: $darkBlue;
        }
    }

}

.panelization-content-wrapper{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    padding: 30px 0;
    gap: 50px;
}
.panelization-render-wrapper {
    background-color: $white;
    /*w600 h650*/
    width: 550px;
    height: 550px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.panelization-render-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.panelization-fields-wrapper{
    display: flex;
    flex-direction: column;
    gap: 30px;
}
.panelization-field{
    display: flex;
    flex-direction: column;
    gap: 10px;

    div{
        display: flex;
        gap: 10px;
    }

    .MuiFormLabel-root{
        font-family: "Montserrat", sans-serif;
        color: $black;
    }
}

.panelization-bottom-bar{
    display: flex;
    justify-content: flex-end;
    width: 100%;
    gap: 30px;
}


/* ------ TABLET ------ */
@media screen and (max-width: 1200px) {
    .panelization-fields-wrapper{
        display: flex;
        justify-content: center;
        flex-direction: row;
        flex-wrap: wrap;
    }
}

/* ------ MOBILE ------ */
@media screen and (max-width: 700px) {

}