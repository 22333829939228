@import '../../../icape-da';

.user-addresses-wrapper{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 30px;
  width: 100%;

  .add-address-cta{
    justify-content: center;
    align-items: center;
    font-size: 120px;
    color: $purple;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    position: relative;

    span{
      font-size: 16px;
      font-weight: 600;
      color: $white;
      position: absolute;
      bottom: 30px;
    }

    &:hover{
      background-color: $purple;
      color: $white;
    }
  }
}
.user-address-card{
  display: flex;
  gap: 20px;
  flex-direction: column;
  width: 300px;
  position: relative;

  border: 1px solid $purple;
  padding: 20px !important;
  border-radius: $border-radius;

  &:hover{

  }
}
.user-address-card-text{
  display: flex;
  flex-direction: column;
  width: 100%;

  span{
    color: $black;
    line-height: 20px;
  }
  span:last-of-type{
    color: $purple;
    font-size: 12px;
    font-weight: 600;
    margin-top: 5px;
  }
  h4{
    font-size: 18px;
    margin-bottom: 5px;
  }
}
.user-address-card-privacy{
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;

  >svg {
    font-size: 25px;
    fill: $purple;
  }
}
.user-address-card-cta{
  display: flex;
  gap: 20px;
  justify-content: space-between;
}


/* ------ TABLET ------ */
@media screen and (max-width: 1200px) {

}

/* ------ MOBILE ------ */
@media screen and (max-width: 700px) {
  .shipping-addresses-wrapper{
    justify-content: center;
  }
}