@import "../icape-da";

/* ------ GLOBAL FOOTER ----- */
.footer {
  width:100%;
  padding: 100px 0 0 0;
/*  margin-top: 50px;*/
  background-color: #353535;
  color: #FFFFFF;
}


/* ------ CONTENT ----- */
.footer-content{
  max-width: 1260px;
  margin: auto;
  padding-bottom: 50px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.footer-logo-symbol{
  width: 360px;
  height: 360px;
}
.footer-menu, .footer-tools{
  display: flex;
  flex-direction: column;
}
.footer-menu-tools-container{
display: flex;
  justify-content: center;
  gap: 100px;
  flex-wrap: wrap;
  flex-grow: 1;
}
.footer-menu{
  gap: 20px;
  flex-grow: 1;

  div{
    display: flex;
    flex-direction: column;
  }
  span{
    font-weight: 500;
  }
  a{
    color: $softBlue2;
    width: fit-content;
  }
  a:hover{
    color: $white;
  }
}
.footer-tools{
  width: 350px;
  align-items: flex-start;
  flex-grow: 2;
}
.footer-nl{
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  margin: 10px 0 0 0;
  width: 100%;

  .MuiOutlinedInput-notchedOutline{
    border-color: $blueIcape;

  }

  .MuiInputBase-root{
    color: $white;

    &:hover .MuiOutlinedInput-notchedOutline{
      border-color: $softBlue;
    }
  }
  .MuiFormLabel-root{
    color: $white;
  }
}
.footer-stock-exchange-title{
  margin: 50px 0 5px 0;
  font-size: 20px;
  font-weight: 500;
}
.footer-stock-exchange{
  width: 100%;
  display: flex;
  gap: 30px;
  justify-content: flex-start;
  align-items: center;
  padding: 20px 0;
  border-top: solid 1px #6E6E6E;
  border-bottom: solid 1px #6E6E6E;

  >div{
    display: flex;
    align-items: center;
    gap: 10px;

    >svg{
      fill: #007DC8;
    }
  }
  span{
    font-size: 20px;
    font-weight: 600;
  }
}

/* ------ COPYRIGHTS ----- */
.copyrights{
  max-width: 1440px;
  font-size: 12px;
  color: #6E6E6E;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px 0;
}
.copyrights-text{
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  a:hover{
    color: $softGrey2;
  }
}
.copyrights-rs{
  fill: #646464;
  display: flex;
  gap: 10px;

  svg:hover{
    fill: $softGrey2;
  }
}



/* ------ TABLET ------ */
@media screen and (max-width: 1200px) {
  .footer-content{
    padding: 0 30px 50px 30px;
  }
  .footer-logo-symbol{
    width: 200px;
    height: 200px;
  }
}

/* ------ MOBILE ------ */
@media screen and (max-width: 700px) {
  .copyrights{
    padding: 20px 30px;
  }
}