@import '../icape-da';

/*----- GLOBAL -----*/
.login-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 50px 0 100px 0;
}
.login-block{
  background-color: white;
  display: flex;
  align-items: flex-start;
  justify-content: center;

  border-radius: $border-radius;
  box-shadow: $border-shadow;
}
.errorMessage{
  display: flex;
  justify-content: center;
  width: fit-content;
  margin: 30px auto 30px auto;
  color: $white;
  font-weight: 500;
  background-color: $red;
  padding: 10px 20px;
  border-radius: $border-radius;
  text-align: center;
}


/* ------ TABLET ------ */
@media screen and (max-width: 1200px) {
  .login-wrapper {
    padding: 0 10px 0 10px;
  }
}

/* ------ MOBILE ------ */
@media screen and (max-width: 700px) {

}