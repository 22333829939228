@import "../shopping-cart.scss";


.shipping-addresses-wrapper{
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.shipping-address-selected-resume{
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  span:first-child{
    font-weight: 700;
    font-size: 18px;
  }
}
.shipping-address-selected-resume-hidden{
  max-height: 0;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}
.shipping-address-selected-resume-show{
  max-height: 300px;
  visibility: visible;
  opacity: 1;
  transition: all 0.3s ease-in-out;
}


/* ------ TABLET ------ */
@media screen and (max-width: 1200px) {

}

/* ------ MOBILE ------ */
@media screen and (max-width: 700px) {

}