@import './../icape-da';

$drawerWidth: 450px;

.library-global-wrapper{
  display: flex;
  width: 100%;
/*  max-width: 1920px;*/
  position: relative;
}

/* ----- LIST MENU -----*/
.library-list-wrapper{
  height: calc(100vh - 110px);
  width: $drawerWidth;
  background-color: $grey;
  color: $white;
  transition: transform 0.3s ease-in-out;
  position: sticky;
  top: 110px;
  z-index: 10;

  hr{
    border-color: $white;
  }


  .drawer-menu{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;

    .library-list-icon{
      display: none;
    }
  }
  .drawer-list{
    width: 100%;
    padding: 20px 0 20px 20px;
    height: calc(100% - 44px);
    overflow-y: scroll;

    h3{
      margin-top: 50px;
      margin-bottom: 10px;
    }
    h3:first-child{
      margin-top: 0;
    }

    .MuiListItemIcon-root{
      min-width: 40px;
      svg{
        font-size: 25px;
      }
    }
    .MuiListItemButton-root{
      padding: 0 0 0 5px;
    }
    .MuiButtonBase-root:hover{
      background-color: transparent;
      color: $softBlue;
      svg{
        fill: $softBlue;
      }
    }
    .MuiPaper-root{
      background-color: $darkWhite;
      //border-radius: $border-radius;
      //color: $white;
      //box-shadow: none;
      }
    .MuiAccordionSummary-content{
      margin: 15px 0;
    }
    .MuiAccordionSummary-root{
      font-weight: bold;
    }
    .main-accordion.Mui-expanded{
      background-color: $softBlue3;
    }
  }
  .drawer-list::-webkit-scrollbar-thumb {
    background-color: $softGrey2 !important;
  }
  .drawer-list::-webkit-scrollbar-thumb:hover {
    background-color: $softGrey !important;
  }
}
.library-list-closed{
  transform: translateX(calc(45px - 100%));
  .drawer-list{
    overflow-y: hidden;
  }
}

/* ----- CONTENT -----*/
.library-display-wrapper{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: flex-start;
  transition: all 0.3s ease-in-out;
  padding-bottom: 200px;

  hr{
    margin-top: 50px;
    border-color: $softBlue;
  }
  svg{
    font-size: 40px;
  }
}
.library-display-title-wrapper{
  background-color: $softBlue3;
  width: 100%;
  height: fit-content;
  padding: 50px;
  display: flex;
  align-items: center;
  gap: 20px;

  span{
    font-size: 14px;
    font-weight: 600;
    color: $blueIcape;
    margin-bottom: 5px;
  }

  .MuiChip-root{
    //background-color: $green;
    margin-right: 10px;
    span{
      color: $white;
    }
  }
}
.library-display-content{
  max-width: 900px;
  width: 100%;
  padding: 50px 50px 0 50px;

  h2{
    margin: 30px 0 10px 0;
    color: $blueIcape;
  }
  h2:first-child{
    margin-top: 0 ;
  }
  h3{
    margin-top: 20px;
    padding-left: 20px;
  }
  h3:first-child{
    margin-top: 0;
  }
  p{
    padding-left: 20px;
  }
  .version-overview{
    padding-left: 0;
  }
}
.library-display-overview-wrapper{
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0 50px 0 50px;
  hr{
    width: 100%;
    background-color: $softBlue;
  }
}


/* ------ TABLET ------ */
@media screen and (max-width: 1200px) {
  /* ----- LIST MENU -----*/
  .library-list-wrapper{
    height: calc(100vh - 80px);
    top: 80px;
    min-width: 100%;
    .drawer-menu{
      .library-list-icon{
        display: flex;
      }
    }
  }
  .library-list-closed{

  }

  /* ----- CONTENT -----*/
  .library-display-wrapper{
    margin-left: calc(45px - 100%);
  }
  .library-display-title-wrapper{
    height: fit-content;
    padding: 50px 10px;
    display: flex;
    align-items: center;
    gap: 20px;

    span{
      color: $blueIcape;
    }
  }
  .library-display-content{
    padding: 30px 10px;
    h3{
      padding-left: 10px;
    }
    p{
      padding-left: 10px;
    }
  }
  .library-display-overview-wrapper{
    padding: 0 10px;
    .library-display-content{
      padding: 30px 0;
    }
  }
}

/* ------ MOBILE ------ */
@media screen and (max-width: 700px) {

}