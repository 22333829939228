@import "../../icape-da";

.cart-table{
  th{
    font-weight: 600;
    padding: 10px 0;
  }
  td{
    padding: 10px;
  };

  tr:last-child td{
    font-weight: 600;
    font-size: 16px;
    border-radius: 0 0 10px 0;
    border-bottom: none;
  }
  th:last-child{
    border-radius: 0 10px 0 0;
  };
  th:last-child, td:last-child{
    color: $softBlue;
    width: 150px;
    padding-right: 20px;
  }
}

.cart-voucher-field{
  display: flex;
  gap: 20px;

  .MuiTextField-root{
    width: 200px;
  }
}

.cart-voucher-list{
  display: flex;
  flex-direction: column;
  gap: 10px;

  p{
    width: fit-content;
    &:hover{
      cursor: pointer;
      span{
        color: $blueIcape;
      }
    }
  }
  span{
    font-weight: 600;
  }
}

.cart-double-item-container {
  display: flex;
  gap: 50px;
  width: 100%;
  margin: 30px 0;
}

.cart-dropzone-container, .cart-voucher {
  display: flex;
  flex-grow: 1;
  gap: 20px;
  flex-direction: column;
  max-width: 50%;
}
.cart-dropzone-container, .cart-voucher:last-child {
  .MuiFormControl-root{
    width: 100%;
  }
}
.cart-dropzone-container:last-child{
  h3{
    color: $purple;
  }
}
.cart-dropzone-container{
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
  max-width: 50%;
}
.cart-dropzone, .cart-spec-dropzone{
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: $border-radius;
  cursor: pointer;
  min-height: 122px;

  img {
    height: 50px;
  }
}
.cart-dropzone{
  gap: 10px;
  border: solid $softBlue 2px;
  padding: 20px;
}
.cart-dropzone-text-container{
  display: flex;
  flex-direction: column;
  gap: 10px;

  .dropzone-text-small{
    font-size: 12px;
    color: $darkBlue;
  }
}

.cart-spec-dropzone{
  border: solid $purple 2px;
  flex-direction: column;
}

.drag-and-drop{
  display: flex;
  padding: 20px;
  width: 100%;
  margin: auto;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.specifications-files-list{
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 20px 30px 20px;
  width: 100%;
  border-top: solid 1px $purple;
}
.file-item{
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;

  svg {
    cursor: pointer;
    fill: $softBlue;
  }
  span{
    flex-grow: 1;
  }
  #delete-gerber-cross{
    svg {
      fill: $red;

      &:hover{
        fill: $darkRed;
      }
    }
  }
}


/* ------ TABLET ------ */
@media screen and (max-width: 1200px) {

}

/* ------ MOBILE ------ */
@media screen and (max-width: 700px) {
  .cart-table{
    th{
      font-size: 12px;
    }
    td{
      font-size: 12px;
    };

    tr:last-child td{
      font-size: 14px;
    }
  }
  .cart-double-item-container{
    flex-direction: column;
  }
  .cart-dropzone-container{
    max-width: 100%;
  }
}