@import '../../../icape-da';

/*----- GLOBAL -----*/
.step1-settings{
  width: 100%;

  h3{
    width: 100%;
  }
  .MuiFormControl-root{
    width: calc(50% - 10px);
  }
}

.panelization-result-display{
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 20px;
  font-size: 14px;
  margin-bottom: 10px;

  span{
    color: $blueIcape;
    font-weight: 500;
    font-size: 16px;
  }
}

/* ------ TABLET ------ */
@media screen and (max-width: 1200px) {

}

/* ------ MOBILE ------ */
@media screen and (max-width: 700px) {
  .step1-form-settings{
    flex-direction: column-reverse !important;
  }
  .drop-bloc {
    width: 100%;
  }
  .drop-bloc[data-isOn="true"] {
    width: 100%;
  }

  #panelization-icon{
    display: none;
  }
}
