@import "../../icape-da";

.payment-gpdr{
  display: flex;
  flex-direction: column;

  label{
    margin-right: 0;
  }

  a{
    font-size: 14px;
    font-weight: 600;
    color: $softBlue;
    width: fit-content;
  }
}
.payment-methods-wrapper{
  display: flex;
  align-items: flex-start;
  gap: 30px;
  width: 100%;
}
.payment-method{
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  width: 100%;
  max-width: 300px;
  gap: 30px;
  padding: 20px;
  border-radius: $border-radius;
  background-color: $softGrey3;
  cursor: pointer;
  height: 65px;
  transition: all 0.3s ease-in-out;
}
.payment-method-loading{
  display: flex;
  align-items: center;
  gap: 10px;
}
.payment-method-active{
  background-color: $softBlue;
  color: $white;
  span{
    font-weight: 600;
  }
  svg path:last-child{
    fill: #FFFFFF;
    transition: all 0.3s ease-in-out;
  }
}
.payment-cta{
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 30px;
  margin-top: 50px;
}


/* ------ TABLET ------ */
@media screen and (max-width: 1200px) {

}

/* ------ MOBILE ------ */
@media screen and (max-width: 700px) {
  .payment-gpdr{
    flex-wrap: wrap;
  }
  .payment-methods-wrapper {
    flex-direction: column;
  }
}